<template>
  <div>{{ msg1 }}</div>
</template>

<script>
export default {
  name: "Operate",
  props: {
    msg: String,
  },
  data() {
    return {
      msg1: "",
    };
  },
  mounted: function () {
    this.msg1 = "12333212";
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
